<template>
    <modal ref="modalAnswersList" titulo="Lista de respuestas" tamano="modal-lg" adicional="Crear" @adicional="add">
        <div class="row f-15">
            <div class="col-12 mb-2">
                <p class="f-12">Crea las opciones de respuesta y añade una puntuación a cada opción</p>
            </div>
            <div class="col-12 my-3">
                <p class="f-12">Pregunta: <span class="text-general f-600"> {{ nombrePregunta }}  </span> </p>
            </div>
        </div>
        <div class="border br-5 p-2 bg-f5">
            <ValidationObserver ref="addAnswersList">
                <div class="row">
                    <div class="col-auto my-auto">
                        <p class="f-10 pl-2">Opción</p>
                        <ValidationProvider v-slot="{errors}" name="opción" rules="required">
                            <el-input v-model="opcionList" placeholder="Agregar opción" size="small" class="w-100" maxlength="30" show-word-limit />
                            <vee-error :text="errors[0]" />
                        </ValidationProvider>
                    </div>
                    <div class="col-auto my-auto">
                        <p class="f-10 pl-2">Calificación</p>
                        <ValidationProvider v-slot="{errors}" name="calificación" rules="required">
                            <el-rate v-model="calificacion" />
                            <vee-error :text="errors[0]" />
                        </ValidationProvider>
                    </div>
                    <div class="col-auto my-auto">
                        <i class="icon-plus-box text-general cr-pointer" @click="addOption" />
                    </div>
                </div>
            </ValidationObserver>
        </div>
        <div class="container-fluid my-4">
            <draggable tag="div" :list="options" class=" border-top" handle=".handle" @change="updatePosOptions">
                <div v-for="item in options" :key="item.id" class="border-bottom br-4 text-muted2 pl-2">
                    <div class="row mx-0 my-2 justify-content-between align-items-center">
                        <div class="col-lg-4 d-flex ml-3">
                            <i class="icon-menu handle cr-pointer mr-2" />
                            <p class="f-12 my-auto">{{ item.opcion }}</p>
                        </div>
                        <div class="col-lg-4 d-flex">
                            <p class="f-12 mr-2">Calificación: </p>
                            <!-- <el-rate :value="getCalification(item.valor)" allow-half disabled></el-rate> -->
                            <el-rate :value="Number(item.valor)" disabled />
                        </div>
                        <div class="d-flex mr-4">
                            <el-tooltip content="Eliminar" effect="light" visible-arrow placement="left">
                                <i class="icon-trash-can-outline icon-general-hover f-20 cr-pointer" @click="deleteOption(item)" />
                            </el-tooltip>
                        </div>
                    </div>
                </div>
            </draggable>
        </div>
    </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    data(){
        return {
            opcionList: '',
            calificacion: 0,
            idAspectos: '',
            nombrePregunta: '',
            
        }
    },
    computed: {
        ...mapGetters({
            options: 'configurar/obras/calificacion_servicio/getQuestionOptionsList',  
        }),
    },
    methods: {
        ...mapActions({
            Action_create_option_question : 'configurar/obras/calificacion_servicio/Action_create_option_question',
            Action_list_options_questions : 'configurar/obras/calificacion_servicio/Action_list_options_questions',
            Action_delete_option_question : 'configurar/obras/calificacion_servicio/Action_delete_option_question',
            Action_update_pos_options : 'configurar/obras/calificacion_servicio/Action_update_pos_options'
        }),
        async toggle(data){
            if (data) {
                this.idAspectos = data.id
                this.nombrePregunta = data.nombre
                await this.Action_list_options_questions(this.idAspectos)
            }
            this.$refs.modalAnswersList.toggle()
        },
        add(){
            this.$refs.modalAnswersList.toggle()
        },
        async addOption(){
            const valid = this.$refs.addAnswersList.validate()
            if (valid) {
                let obj = {
                    opcion: this.opcionList,
                    valor: this.calificacion,
                    id_configuracion_control_piso_aspectos_calificar: this.idAspectos
                }
                await this.Action_create_option_question(obj)
                this.clearOptions()
                this.$refs.addAnswersList.reset()
            }
        },
        // getCalification(val){
        //     return ((val*5)/100)
        // },
        async deleteOption(item){
            let obj = {
                id_configuracion_control_piso_aspectos_calificar: item.id_configuracion_control_piso_aspectos_calificar,
                id_configuracion_control_piso_aspectos_calificar_opciones: item.id
            }
            await this.Action_delete_option_question(obj)
            //await this.Action_list_options_questions(this.idAspectos)
        },
        clearOptions(){
            this.opcionList = '',
            this.calificacion = ''
        },
        async updatePosOptions(option){
            let actividades = []
            this.options.forEach((element, index, array )=> {
                console.log('this.idConfControlPisoAspectCalif=>',element.id_configuracion_control_piso_aspectos_calificar);
                element.posicion = index
                actividades.push({
                            id_configuracion_control_piso_aspectos_calificar_opciones: element.id,
                            posicion: index,
                        })
            });
            const payload = {
                id: this.idAspectos,
                payload: {opciones:_.orderBy(actividades,'posicion','asc')}
            }

            await this.Action_update_pos_options(payload)
        }
        }
    }

</script>