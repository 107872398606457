<template>
    <modal ref="modalEditQuestion" titulo="Crear pregunta" adicional="Guardar" @adicional="add">
        <ValidationObserver ref="editQuestion">
            <div class="row m-3 f-15">
                <div class="col-12 mb-3">
                    <p class="input-label-top">Pregunta</p>
                    <ValidationProvider v-slot="{errors}" name="pregunta" rules="required">
                        <el-input v-model="question" type="textarea" class="w-100" size="small" ref="activityName" maxlength="255" show-word-limit :autosize="{ minRows: 4, maxRows: 4}" />
                        <vee-error :text="errors[0]" />
                    </ValidationProvider>
                </div>
                <div class="col-12">
                    <p class="input-label-top">Tipo de respuesta</p>
                    <ValidationProvider v-slot="{errors}" name="tipo" rules="required">
                        <el-select v-model="tipo" placeholder="Seleccionar tipo" size="small" class="w-100">
                            <el-option v-for="item in questionType" :key="item.value" :label="item.name" :value="item.value">
                                <i :class="item.icon" style="float: left" />
                                <span class="ml-2" style="color: #8492a6; font-size: 13px">{{ item.name }}</span>
                            </el-option>
                        </el-select>
                        <vee-error :text="errors[0]" />
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    data(){
        return {
            question: '',
            questionType: [
                {
                    value: 6,
                    name: 'Puntuación',
                    icon: 'icon-star f-21'
                }, 
                {
                    value: 5,
                    name: 'Opciones de respuesta',
                    icon: 'icon-dots-grid f-21'
                }, 
            ],
            tipo: ''
        }
    },
    methods: {
        ...mapActions({
            Action_list_questions_workshops: 'configurar/obras/calificacion_servicio/Action_list_questions_workshops',
            Action_edit_question : 'configurar/obras/calificacion_servicio/Action_edit_question'
        }),
        toggle(data){
            if (data) {
                this.id = data.id
                this.question = data.nombre
                this.tipo = data.id_tipo
            }
            this.$refs.modalEditQuestion.toggle()
        },
        async add(){
            const valid = this.$refs.editQuestion.validate()
            if (valid) {
                let obj = {
                    id_configuracion_control_piso_aspectos_calificar: this.id,
                    nombre: this.question,
                    id_tipo: this.tipo
                }

                await this.Action_edit_question(obj)
                this.$refs.modalEditQuestion.toggle()
                await this.Action_list_questions_workshops()

            }
        },
    }
}
</script>