<template>
    <section class="config-obras-calificacion-servicio">
        <!-- title + addList -->
        <titulo-divisor titulo="Preguntas de calificación">
            <el-tooltip content="Crear pregunta" effect="light" visible-arrow>
                <i class="icon-plus-circle text-general f-30 cr-pointer" @click="createNewQuestion" />
            </el-tooltip>
        </titulo-divisor>
        <div class="container-fluid">
            <draggable tag="div" :list="questionsList" class=" border-top" handle=".handle" @change="updatePosQuestion">
                <div v-for="add in questionsList" :key="add.id" class="border-bottom br-4 text-muted2 pl-2">
                    <div class="row mx-0 my-2 justify-content-between align-items-center">
                        <div class="col-lg-4 d-flex ml-3">
                            <i class="icon-menu handle cr-pointer mr-2" />
                            <p class="f-12 my-auto">{{ add.nombre }}</p>
                        </div>
                        <div class="col-lg-4 d-flex">
                            <div class="col-auto border br-5 px-0" style="width: 225px;">
                                <div class="d-flex br-5 align-items-center">
                                    <i class="bg-db" :class="getTypeIcon(add.id_tipo)" />
                                    <p class="text-muted f-13 f-600 ml-2">{{DATA_TYPE_INFO[add.id_tipo] && DATA_TYPE_INFO[add.id_tipo].name || DATA_TYPE_INFO_DEFAULT.name}}</p>
                                </div>
                            </div>
                            <el-tooltip content="Editar Valores" effect="light" visible-arrow>
                                <i v-if="VALUE_LIST_CODE.includes(add.id_tipo)" class="icon-pencil icon-general-hover f-20 my-auto cr-pointer"  @click="editAnswersList(add)" />
                            </el-tooltip>
                        </div>
                        <div class="d-flex mr-4">
                            <i class="icon-pencil icon-general-hover f-20 mr-1 cr-pointer" @click="editQuestion(add)" />
                            <i class="icon-trash-can-outline icon-general-hover f-20 cr-pointer" @click="deleteQuestion(add.id)" />
                        </div>
                    </div>
                </div>
            </draggable>
        </div>
        <!-- title + addList -->
        <titulo-divisor titulo="Define que preguntas aplican para cada taller" class="my-5" />
        <div class="cotainer-fluid overflow-auto custom-scroll border br-10 p-3">
            <div class="preguntas-talleres d-flex position-relative">
                <div class="preguntas d-middle px-3 bg-white" style="position: sticky; left: 0px; z-index: 9;">
                    <p class="f-13 f-600">Preguntas</p>
                </div>
                <div v-for="(data,idx) in allWorkShops" :key="idx" class="taller border-0 text-center">
                    <el-checkbox v-model="questions[idx]" @change="checkAllQuestions($event,idx, data)" />
                    <div class="taller-nombre mx-auto br-5 tres-puntos px-2 d-middle-center f-14">
                        <p class="tres-puntos">{{ data.nombre }}</p>
                    </div>
                </div>
            </div>
            <div v-for="(data, index) in questionsWorkShopList" :key="index" class="preguntas-talleres listado d-flex my-2 py-1 position-relative">
                <div v-if="data.talleres" class="preguntas border-bottom d-middle px-3 bg-white" style="position: sticky; left: 0px; z-index: 9;">
                    <el-checkbox v-model="workshops[index]" @change="checkAllWorkShops($event,index, data.id_pregunta)" />
                    <p class="f-12 ml-2"> {{ data.nombre }} </p>
                </div>
                <div v-for="(data2, idx) in data.talleres" :key="idx" class="taller pb-1 text-center">
                    <el-checkbox v-model="data2.vmodel" @change="saveQuestionWorkShop($event, data2, data)" />
                </div>
            </div>
        </div>
        <!-- partials -->
        <modal-create-question ref="openModalCreateQuestion" />
        <modal-edit-question ref="openModalEditQuestion" />
        <modal-answers-list ref="openModalAnswersList" />
        <modal-eliminar ref="openModaldeleteQuestions" title="Eliminar pregunta" mensaje="¿Está seguro que quiere eliminar esta pregunta?" @delete="handleDeleteQuestion" />
    </section>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import modalCreateQuestion from './partials/modalCreateQuestion.vue'
import modalEditQuestion from './partials/modalEditQuestion.vue'
import modalAnswersList from './partials/modalAnswersList.vue'
export default {
    name: 'ConfCalificacionServicio',
    components: {
        modalCreateQuestion,
        modalEditQuestion,
        modalAnswersList,
    },
    data() {
        return {
            inputEditDato: '',
            VALUE_LIST_CODE: [2,3,5],
            validEdit: true,
            DATA_TYPE_INFO: {
                6: {
                    id: 6,
                    name: 'Puntuación',
                    icon: 'icon-star br-l-4 text-white f-21 my-auto px-1 bg-db'
                },
                5: {
                    id: 5,
                    name: 'Opciones de respuesta',
                    icon: 'icon-dots-grid br-l-4 text-white f-21 my-auto px-1 bg-db'
                },
            },
            DATA_TYPE_INFO_DEFAULT: {
                id: 0,
                name: 'Sin tipo',
                icon: 'icon-information-outline br-l-4 text-white f-21 my-auto px-1 bg-db'
            },
            questions: [],
            workshops: [],
            checked: false,
            checkedTalleres: false,
            questionToDelete: null,
            allWorkShops: [],
            allQuestions: [],
            toChanges: []
        }
    },
    computed: {
        ...mapGetters({
            questionsList: 'configurar/obras/calificacion_servicio/getQuestionList',  
            questionsWorkShopList: 'configurar/obras/calificacion_servicio/getQuestionsWorkShopList', 
        }),
    },
    async mounted(){
        //await this.LIST_QUESTIONS_LIST()
        await this.Action_list_questions()
        await this.Action_list_questions_workshops()
        this.getFullTalleres()
        this.setQuestions()
        this.setWorkshops()
    },
    methods: {
        ...mapActions({
            LIST_QUESTIONS_LIST: 'configurar/obras/calificacion_servicio/LIST_QUESTIONS_LIST',
            Action_list_questions: 'configurar/obras/calificacion_servicio/Action_list_questions',
            Action_delete_question: 'configurar/obras/calificacion_servicio/Action_delete_question',
            Action_list_questions_workshops: 'configurar/obras/calificacion_servicio/Action_list_questions_workshops',
            Action_question_workshop: 'configurar/obras/calificacion_servicio/Action_question_workshop',
            Action_workshop_question: 'configurar/obras/calificacion_servicio/Action_workshop_question',
            Action_update_pos_questions: 'configurar/obras/calificacion_servicio/Action_update_pos_questions'
        }),
        async checkAllQuestions(valor,idx,data){
            console.log('check questions');
            let changed = []
            this.questionsWorkShopList.map(e => {
                if (e.talleres[idx].vmodel != valor) {
                    changed.push(e.id_pregunta)
                    e.talleres[idx].vmodel = valor
                }
            })

            let obj = {
                id_pregunta: changed,
                id_taller: data.id
            }
            this.setWorkshops();
            await this.Action_workshop_question(obj)
        },
        async checkAllWorkShops(valor,idx, id_pregunta){
            console.log('check workshops');
            let changed = []
            this.questionsWorkShopList.forEach(e => {
                if (e.id_pregunta == id_pregunta ) {
                    if(e.talleres.every(el => el.vmodel == true)) {
                        changed = e.talleres.map(el => el.id)
                        e.talleres = e.talleres.map(el => ({...el, vmodel: valor}))
                    } else{
                        e.talleres.forEach(element => {
                            if(element.vmodel == false) changed.push(element.id)
                            element.vmodel = valor
                        });
                    }
                }
            })

            let obj = {
                id_pregunta: id_pregunta,
                id_taller: changed
            }
            this.setQuestions();
            await this.Action_question_workshop(obj)
        },
        async saveQuestionWorkShop(e,data2, data){

            let questionSelected = this.questionsWorkShopList.find(e => e.id_pregunta == data.id_pregunta)

            let idsWorkShopSelected = [];
            questionSelected.talleres.forEach(e => {
                if (e.vmodel) idsWorkShopSelected.push(e.id)
            })

            let obj = {
                id_pregunta: data.id_pregunta,
                id_taller: [data2.id]
            }

            this.setQuestions();
            this.setWorkshops();
            await this.Action_question_workshop(obj)
        },
        setQuestions(){
            for(var i = 0; i < this.questionsWorkShopList.length; i++){
                if(this.questions?.[i]) this.questions[i] = this.questionsWorkShopList.every(el => el.talleres?.[i].vmodel == true);
            }
        },
        setWorkshops(){
            for(var i = 0; i < this.questionsWorkShopList.length; i++){
                if(this.workshops?.[i]) this.workshops[i] = this.questionsWorkShopList?.[i]?.talleres.every(el => el.vmodel == true)
            }
        },
        getFullTalleres(){
            const data = this.questionsWorkShopList?.[0]?.talleres ?? []
            this.allWorkShops =  _.cloneDeep(data);
        },
        getFullQuestions(){
            this.allQuestions =  this.questionsList ? _.cloneDeep(this.questionsList) : []
        },
        createNewQuestion() {
            this.$refs.openModalCreateQuestion.toggle()
        },
        editQuestion(data) {
            this.$refs.openModalEditQuestion.toggle(data)
        },
        editAnswersList(data) {
            this.$refs.openModalAnswersList.toggle(data)
        },
        deleteQuestion(id) {
            this.questionToDelete = id
            this.$refs.openModaldeleteQuestions.toggle()

        },
        getTypeIcon(dataType){
            return this.DATA_TYPE_INFO?.[dataType]?.icon ?? this.DATA_TYPE_INFO_DEFAULT.icon
        },
        updateAdditionalData(data){

        },
        editInput(){

        },
        async handleDeleteQuestion(){
            await this.Action_delete_question(this.questionToDelete)
            await this.Action_list_questions_workshops()
        },
        async updatePosQuestion(question){
            let actividades = []
            this.questionsList.forEach((element, index, array )=> {
                element.posicion = index
                actividades.push({
                    id_configuracion_control_piso_aspectos_calificar: element.id,
                    posicion: index
                })
            });
            await this.Action_update_pos_questions({preguntas:_.orderBy(actividades,'posicion','asc')})
            this.Action_list_questions_workshops({preguntas:_.orderBy(actividades,'posicion','asc')})
        }
    },

}
</script>

<style lang="scss" scoped>
.config-obras-calificacion-servicio{
    .preguntas-talleres{
        /* &.listado{
            border-bottom: 1px solid #ECECEC;
            .preguntas{
                border-bottom: 1px solid #ECECEC;
            }
            .taller{
                border-bottom: 1px solid #ECECEC;
            }
        } */
        .preguntas{
            max-width: 450px;
            min-width: 450px;
        }
        .taller{
            max-width: 150px;
            min-width: 150px;
            border-bottom: 1px solid #ECECEC;
            .taller-nombre{
                width: 138px;
                height: 39px;
                background: #ECECEC;
            }
        }
    }
}
</style>